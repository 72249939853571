/* eslint-disable complexity */
(function () {
    'use strict';
  
    // Add ECMA262-5 method binding if not supported natively
    if (!('bind' in Function.prototype)) {
      Function.prototype.bind = function (owner) {
        var that = this;
        var args;
  
        if (arguments.length <= 1) {
          return function () {
            return that.apply(owner, arguments);
          };
        }
        args = Array.prototype.slice.call(arguments, 1);
  
        return function () {
          return that.apply(owner, arguments.length === 0 ?
            args :
            args.concat(Array.prototype.slice.call(arguments)));
        };
      };
    }
  
    // Add ECMA262-5 string trim if not supported natively
    if (!String.prototype.trim) {
      (function () {
        // Make sure we trim BOM and NBSP
        var rtrim = /u^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/ug;
  
        String.prototype.trim = function () {
          return this.replace(rtrim, '');
        };
      })();
    }
  
    // Add ECMA262-5 Array methods if not supported natively
    if (!('indexOf' in Array.prototype)) {
      Array.prototype.indexOf = function (lfind, i) {
        var n;
        var j = i;
  
        if (j === undefined) {
          j = 0;
        }
        if (j < 0) {
          j += this.length;
        }
        if (j < 0) {
          j = 0;
        }
        for (n = this.length; j < n; j++) {
          if (j in this && this[j] === lfind) {
            return j;
          }
        }
  
        return -1;
      };
    }
  
    if (!('lastIndexOf' in Array.prototype)) {
      Array.prototype.lastIndexOf = function (lfind, i) {
        var j = i;
  
        if (j === undefined) {
          j = this.length - 1;
        }
        if (j < 0) {
          j += this.length;
        }
        if (j > this.length - 1) {
          j = this.length - 1;
        }
        // The i++ because from-argument is sadly inclusive
        for (j++; j-- > 0;) {
          if (j in this && this[j] === lfind) {
            return j;
          }
        }
  
        return -1;
      };
    }
  
    if (!('forEach' in Array.prototype)) {
      Array.prototype.forEach = function (action, that) {
        var i;
        var n;
  
        for (i = 0, n = this.length; i < n; i++) {
          if (i in this) {
            action.call(that, this[i], i, this);
          }
        }
      };
    }
  
    if (!('map' in Array.prototype)) {
      Array.prototype.map = function (mapper, that) {
        var other = new Array(this.length);
        var i;
        var n;
  
        for (i = 0, n = this.length; i < n; i++) {
          if (i in this) {
            other[i] = mapper.call(that, this[i], i, this);
          }
        }
  
        return other;
      };
    }
  
    if (!('filter' in Array.prototype)) {
      Array.prototype.filter = function (filter, that) {
        var other = [];
        var v;
        var i;
        var n;
  
        for (i = 0, n = this.length; i < n; i++) {
          if (i in this && filter.call(that, v = this[i], i, this)) {
            other.push(v);
          }
        }
  
        return other;
      };
    }
  
    if (!('every' in Array.prototype)) {
      Array.prototype.every = function (tester, that) {
        var i;
        var n;
  
        for (i = 0, n = this.length; i < n; i++) {
          if (i in this && !tester.call(that, this[i], i, this)) {
            return false;
          }
        }
  
        return true;
      };
    }
  
    if (!('some' in Array.prototype)) {
      Array.prototype.some = function (tester, that) {
        var i;
        var n;
  
        for (i = 0, n = this.length; i < n; i++) {
          if (i in this && tester.call(that, this[i], i, this)) {
            return true;
          }
        }
  
        return false;
      };
    }
  })();